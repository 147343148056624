import React, { useState } from 'react';
import './App.css';

function App() {
  const [people, setPeople] = useState([]);
  const [personName, setPersonName] = useState('');
  const [carSeats, setCarSeats] = useState(4);
  const [result, setResult] = useState(null);
  const [disable, setDisable] = useState(false)

  const addPerson = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (personName.trim()) {
      setPeople([...people, personName]);
      setPersonName('');
    }
  };

  const calculateDrivers = () => {
    const carNumber = people.length % carSeats === 0 ? people.length / carSeats : Math.floor(people.length / carSeats) + 1;
    console.log({carNumber})
    console.log({people})
    const nonDrivers = Object.assign([], people);
    const drivers = [];
    for (let i = 0; i < carNumber; i++) {
      const idx = Math.floor(Math.random() * nonDrivers.length)
      drivers.push(nonDrivers.splice(idx,1))
    }
    setResult({ drivers, nonDrivers });
    setDisable(true)
  };

  return (
    <div className="App">
      <h1>Gestione Posti per Macchina</h1>

      <form onSubmit={(e) => addPerson(e)} className="input-group">
        <input
          type="text"
          placeholder="Inserisci nome persona"
          value={personName}
          onChange={(e) => setPersonName(e.target.value)}
        />
        <button type='submit'>Aggiungi</button>
      </form>

      <div className="list">
        <h2>Lista Persone</h2>
        <ul>
          {people.map((person, index) => (
            <li key={index}>{person}</li>
          ))}
        </ul>
      </div>

      <div className="input-group">
        <input
          type="number"
          placeholder="Numero di posti in macchina"
          value={carSeats}
          onChange={(e) => setCarSeats(e.target.value)}
        />
        <button onClick={calculateDrivers} disabled={disable}>Calcola Guidatori</button>
      </div>

      {result && (
        <div className="result">
          {typeof result === 'string' ? (
            <p>{result}</p>
          ) : (
            <>
              <h2>Risultati</h2>
              <p><strong>Guidatori:</strong> {result.drivers.join(', ')}</p>
              <p><strong>Salvati:</strong> {result.nonDrivers.join(', ')}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default App;